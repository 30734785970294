import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useProfile from "@/hooks/useProfile";
import useStatus from "@/hooks/useStatus";
import useTranslations from "@/hooks/useTranslations";
import useUnreadMessages from "@/hooks/useUnreadMessages";

import { LeftNavButton, LeftNavLink } from "./LeftNavItems";
import DesktopProfileMenu from "../DesktopProfileMenu";
import NotifyButton from "../../NotifyButton";

import LogoWhite from "@velaro/velaro-shared/src/images/Logo_White.svg";
import IconContacts from "@/icons/IconContacts";
import Ticket from "@velaro/velaro-shared/src/svg/stroke-icons/ticket-01.svg?react";
import MessageDotSquare from "@velaro/velaro-shared/src/svg/stroke-icons/message-dots-square.svg?react";
import ReportChartIcon from "@velaro/velaro-shared/src/svg/stroke-icons/bar-chart-12.svg?react";
import SettingsIcon from "@velaro/velaro-shared/src/svg/stroke-icons/settings-01.svg?react";
import HelpIcon from "@velaro/velaro-shared/src/svg/stroke-icons/help-circle.svg?react";
import useSubscriptions from "@/hooks/useSubscriptions";

const adminUrl = import.meta.env.VITE_ADMIN_URL as string;

export default function LeftNav() {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { profile } = useProfile();
  const translation = useTranslations();
  const { subscription } = useSubscriptions();

  const { totalCount } = useUnreadMessages();

  const lng = navigator.language;
  const { status } = useStatus();
  const availabilityColor =
    status === "Available"
      ? "bg-success-500"
      : status === "Unavailable"
      ? "bg-sweet-orange-500"
      : "bg-slate-500";
  const location = useLocation();
  return (
    <div className="w-24 h-screen px-4 pb-4 pt-6 bg-cornflower-blue-500 flex-col items-center justify-between inline-flex text-white">
      <div className="pb-6">
        <img className="w-[44px] h-[38px]" src={LogoWhite} alt="Velaro Logo" />
      </div>
      <div className="flex flex-col grow justify-start">
        <LeftNavLink
          to="/conversations"
          label={translation.key("messaging")}
          selected={location.pathname === "/conversations"}
          icon={<MessageDotSquare />}
          enabled={true}
          count={totalCount}
        />
        <LeftNavLink
          to="/dashboard"
          label={translation.key("insights")}
          selected={location.pathname === "/dashboard"}
          icon={<ReportChartIcon />}
          enabled={true}
        />
        <LeftNavLink
          to="/contacts"
          label={translation.key("contacts")}
          selected={location.pathname === "/contacts"}
          icon={<IconContacts />}
          enabled={true}
        />
        <LeftNavLink
          to="#"
          label="Tickets"
          icon={<Ticket />}
          enabled={subscription?.enableTicketing}
        />
      </div>
      <div className="flex flex-col grow justify-end items-center">
        <div className="w-full h-px bg-cornflower-blue-400 rounded-full" />
        {profile.roles && profile.roles.includes("Administrator") && (
          <div className="transition duration-200 delay-100 hover:bg-cornflower-blue-400 hover:delay-100 rounded-lg px-[14px]">
            <a href={adminUrl} target="_blank" rel="noreferrer">
              <LeftNavButton icon={<SettingsIcon />}>
                {translation.key("admin")}
              </LeftNavButton>
            </a>
          </div>
        )}
        <div className="w-full h-px bg-cornflower-blue-400 rounded-full" />
        <div className="relative flex items-center justify-center py-[8.5px] px-[5px">
          <button
            className="w-full flex items-center justify-center relative py-2"
            onClick={(e) => {
              setShowProfileMenu(!showProfileMenu);
              e.stopPropagation();
            }}
          >
            <img
              className="h-12 w-12 rounded-full border"
              src={profile.picture}
              alt=""
            />
            <div
              className={`absolute w-4 h-4 rounded-full ${availabilityColor} right-2 bottom-4 transform translate-x-[50%] translate-y-[50%] border-2 border-white`}
            ></div>
          </button>
          {showProfileMenu && (
            <DesktopProfileMenu onClose={() => setShowProfileMenu(false)} />
          )}
        </div>
        <div className="w-full h-px bg-cornflower-blue-400 rounded-full" />
        <NotifyButton />
        <div className="transition duration-200 delay-100 hover:bg-cornflower-blue-400 hover:delay-100 rounded-lg px-5">
          <a href="https://help.velaro.com/" target="_blank" rel="noreferrer">
            <LeftNavButton icon={<HelpIcon />}>
              {translation.key("help")}
            </LeftNavButton>
          </a>
        </div>
      </div>
    </div>
  );
}

import { Tag } from "@/types";
import React, { useState } from "react";
import TagModal from "./TagModal";
import RemoveTagBadge from "@velaro/velaro-shared/src/components/Badges/RemoveTagBadge";
import useTranslations from "@/hooks/useTranslations";
import TagModal1 from "./TagModal1";

interface Props {
  label: string;
  labelSize?: "sm" | "md";
  onSave: (tags: Tag[]) => void;
  onRemove: (tag: Tag) => void;
  allTags: Tag[];
  selectedTags: Tag[];
  badgeSize?: "sm" | "md";
  createTag(label: string): Promise<Tag>;
  updateTag(tag: Tag): Promise<Tag>;
  saving: boolean;
}

export default function Tags(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const translation = useTranslations();

  const labelSize = props.labelSize === "sm" ? "text-sm" : "";

  return (
    <>
      <div className="dark:text-white">
        <div className={`${labelSize} mb-2 font-medium`}>{props.label}</div>
        <div className="flex flex-wrap items-center gap-2">
          {props.selectedTags.map((tag) => (
            <RemoveTagBadge
              key={tag.id}
              label={tag.name}
              onClick={() => props.onRemove(tag)}
              size={props.badgeSize}
              color={tag.color}
            />
          ))}
          <button
            type="button"
            className="h-6 flex items-center rounded px-2 mr-1 text-sm hover:bg-slate-gray-100 hover:cursor-pointer hover:text-cornflower-blue-500"
            onClick={() => setShowModal(true)}
          >
            <i className="fa fa-plus"></i>
            <span className="ml-1 text-sm">{translation.key("add_tag")}</span>
          </button>
        </div>
      </div>
      {showModal && (
        <TagModal1
          allTags={props.allTags}
          associatedTags={props.selectedTags}
          onClose={() => setShowModal(false)}
          createTag={props.createTag}
          updateTag={props.updateTag}
          saveTags={props.onSave}
          saving={props.saving}
        />
      )}
    </>
  );
}

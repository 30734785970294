import React from "react";
import { Subscription } from "@/providers/SubscriptionsProvider";

export const SubscriptionsContext = React.createContext<{
  subscription: Subscription;
  loading: boolean;
}>({
  subscription: {
    id: 0,
    siteId: 0,
    enableWeb: false,
    enableEmail: false,
    enableSms: false,
    enableWhatsapp: false,
    enableFacebook: false,
    enableNetsuite: false,
    enableAutomation: false,
    enableAI: false,
    enableWorkflowRules: false,
    enableFormstack: false,
    enableContacts: false,
    enableKnowledgeBase: false,
    enableInvitations: false,
    enableTicketing: false
  },
  loading: true
});

import { defaultInboxFilters, defaultInboxState } from "@/constants";
import { InboxAction } from "@/reducers/inboxReducer";
import { ConversationBaseListItem, InboxContent, InboxSettings } from "@/types";
import React from "react";

interface Props {
  state: InboxContent;
  dispatch: React.Dispatch<InboxAction>;
  setSettings(settings: InboxSettings): void;
  settings: InboxSettings;
  groupedConversations: Record<string, ConversationBaseListItem[]>;
}

const InboxContext = React.createContext<Props>({
  state: defaultInboxState,
  dispatch: () => {},
  setSettings: () => {},
  settings: {
    sortBy: "newest",
    filters: defaultInboxFilters,
    selectedTab: "mine",
    groupBy: "none",
    searchQuery: ""
  },
  groupedConversations: {}
});

export default InboxContext;

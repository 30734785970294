import NotificationContext from "@/context/NotificationContext";
import useApi from "@/hooks/useApi";
import useProfile from "@/hooks/useProfile";
import useSubscribe from "@/hooks/useSubscribe";
import { ConversationBaseListItem, NotificationSettings } from "@/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useStatus from "@/hooks/useStatus";
import ReassignmentNotification from "@/components/ReassignmentNotification";
import { createRoot } from "react-dom/client";

interface NotificationOpts {
  title: string;
  body: string;
  actions?: { action: string; title: string; icon: string }[];
  data?: any;
}

let notificationWorkerReg: ServiceWorkerRegistration;
let clientId = "";
export default function NotificationProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const subscribe = useSubscribe();
  const api = useApi();
  const navigate = useNavigate();
  const { profile } = useProfile();
  const toastAlert = useToastAlert();
  const { status } = useStatus();
  const [settings, setSettings] = useState<NotificationSettings>({
    newConversations: false,
    newMessages: false,
    unassignWarning: false,
    textAlerts: false,
    conversationAssigned: false
  });
  const notificationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function fetchSettings() {
      const resp = await api.messaging.get("NotificationSettings");
      setSettings(await resp.json());
    }

    fetchSettings();
  }, [api.messaging]);

  function updateSettings(updates: Partial<NotificationSettings>) {
    const newSettings = { ...settings, ...updates };
    setSettings(newSettings);
    persistSettings(newSettings);
  }

  async function persistSettings(settings: NotificationSettings) {
    const resp = await api.messaging.post("NotificationSettings", settings);

    if (!resp.ok) {
      toastAlert.displayToast(
        Severity.Error,
        "Failed to save settings to server."
      );
    }
  }

  const [currentconversationBaseId, setCurrentconversationBaseId] =
    React.useState<number | null | undefined>();

  const onNotificationAction = useCallback(
    async (message: any) => {
      const notification = message.notification;
      console.log("clicked notification", notification);

      switch (message.action) {
        case "click":
          navigate(`/conversations/${notification.conversationBaseId}`);
          break;

        case "claim":
          {
            const response = await api.messaging.post("AssignAgent", {
              userId: profile.id,
              conversationBaseId: notification.conversationBaseId
            });
            if (!response.ok) {
              if (response.status === 400) {
                alert(await response.text());
              } else {
                toastAlert.displayToast(
                  Severity.Error,
                  "an unexpected error has occurred"
                );
              }
            }
            navigate(`/conversations/${notification.conversationBaseId}`);
          }
          break;
        case "ignore":
          break;

        default:
          console.error(
            "unknown action type from notification",
            message.action
          );
          break;
      }
    },
    [api.messaging, navigate, profile.id, toastAlert]
  );

  const onClickPlainNotification = useCallback(
    (e: Event) => {
      const conversationBaseId = (e.currentTarget as Notification)?.data
        ?.conversationBaseId;
      if (conversationBaseId) {
        navigate(`/conversations/${conversationBaseId}`);
      }
    },
    [navigate]
  );

  const showNotification = useCallback(
    (opts: NotificationOpts) => {
      const hasNotificationApi = "Notification" in window;
      if (!hasNotificationApi) {
        console.warn("skipping notification. Notification API not supported");
        return;
      }

      if (Notification.permission !== "granted") {
        console.warn("skipping notification. Permission not granted");
        return;
      }

      if (status === "Unavailable") {
        console.warn("skipping notification. User is unavailable");
        return;
      }

      if (notificationWorkerReg?.active?.state === "activated") {
        if (!clientId) {
          console.error(
            "Missing clientId when showing worker notification. Notification actions will not work."
          );
        }
        opts.data.clientId = clientId;
        console.log("showing worker notification", opts);
        notificationWorkerReg.showNotification(opts.title, {
          body: opts.body,
          data: opts.data,
          requireInteraction: false,
          actions: opts.actions
        });
      } else {
        console.log("showing plain notification", opts);
        const notification = new Notification(`${opts.title}: ${opts.body}`, {
          data: opts.data
        });
        notification.onclick = onClickPlainNotification;
      }
    },
    [onClickPlainNotification, status]
  );

  const onReceiveWorkerMessage = useCallback(
    (e: MessageEvent) => {
      const message = e.data;
      switch (message.type) {
        case "clientId":
          clientId = message.clientId;
          break;
        case "notification_click":
          onNotificationAction(message.data);
          break;
        default:
          console.error(
            "unknown message type from service worker",
            message.type
          );
          break;
      }
    },
    [onNotificationAction]
  );

  const goToConversation = useCallback(
    (conversationBaseId: number, alertId: string) => {
      navigate(`/conversations/${conversationBaseId}`);
      closeNotification(alertId.toString());
    },
    [navigate]
  );

  const displayReassignmentNotification = useCallback(
    (
      conversation: ConversationBaseListItem,
      message: string,
      previousAssignedUser?: string
    ) => {
      const r = (Math.random() + 1).toString(36).substring(7);
      const newElement = document.createElement("div");
      newElement.id = r;
      const container = notificationContainer.current;
      if (container === null) {
        return;
      }
      container.appendChild(newElement);
      const root = createRoot(newElement);
      root.render(
        <ReassignmentNotification
          conversation={conversation}
          previousAssignedUser={previousAssignedUser}
          message={message}
          id={r}
          onClose={closeNotification}
          goToConversation={goToConversation}
        />
      );
    },
    [goToConversation]
  );

  const regiserServiceWorker = useCallback(async () => {
    if (!("serviceWorker" in navigator)) {
      console.warn("Service workers are not supported.");
      return;
    }
    const swUrl = new URL("notificationWorker.js", origin);
    try {
      notificationWorkerReg = await navigator.serviceWorker.register(
        swUrl.toString()
      );

      navigator.serviceWorker.onmessage = onReceiveWorkerMessage;

      const registration = await navigator.serviceWorker.ready;
      console.log("registration", registration);
      if (registration.active) {
        //send a message to the service worker so that it can respond with our clientId
        registration.active.postMessage({ type: "clientId" });
      }
    } catch (error) {
      console.error(`Service worker registration failed: ${error}`);
    }
  }, [onReceiveWorkerMessage]);

  useEffect(() => {
    regiserServiceWorker();
  }, [regiserServiceWorker]);

  useEffect(
    () =>
      subscribe("conversation-created", (message) => {
        if (!settings.newConversations) {
          return;
        }

        // dont show notification if it's a bot chat
        if (
          message.conversationBase.automationJobId !== null &&
          message.conversationBase.automationJobStatus === "in_progress"
        ) {
          return;
        }

        // dont show notification if conversation has been auto assigned
        if (message.conversationBase.assignedUserId !== null) {
          return;
        }

        showNotification({
          title: `${message.conversationBase.contactName} has started a new conversation.`,
          body: ``,
          actions: [
            {
              title: "Claim",
              action: "claim",
              icon: "/images/accept.png"
            },
            {
              title: "Ignore",
              action: "ignore",
              icon: "/images/reject.png"
            }
          ],
          data: {
            conversationBaseId: message.conversationBase.id
          }
        });
      }),
    [navigate, settings.newConversations, showNotification, subscribe]
  );

  useEffect(
    () =>
      subscribe("receive-message", (message) => {
        if (!settings.newMessages) {
          return;
        }

        // todo: handle non-text messages

        // don't show notification if it's an auto response
        if (message.message.isAutoResponse) {
          return;
        }

        // don't show notification if sender was me.
        if (message.message.user?.id === profile.id) {
          return;
        }

        // don't show notification if I'm not assigned to the conversation
        if (message.assignedUserId !== profile.id) {
          return;
        }

        // don't show message if the window is focused and i'm viewing the conversation.
        if (currentconversationBaseId === message.message.conversationBaseId) {
          return;
        }

        showNotification({
          title: "New Message",
          body: `${
            message.message.user?.name ||
            message.message.contact?.name ||
            "System"
          }: ${message.message.text}`,
          actions: [],
          data: {
            conversationBaseId: message.message.conversationBaseId
          }
        });
      }),
    [
      navigate,
      profile.id,
      currentconversationBaseId,
      subscribe,
      settings.newMessages,
      showNotification
    ]
  );

  useEffect(
    () =>
      subscribe("unassign-warning", (message) => {
        if (!settings.unassignWarning) {
          return;
        }
        // don't show notification if I'm not assigned to the conversation
        if (message.conversation.assignedUserId !== profile.id) {
          return;
        }

        let body = `Conversation will be unassigned due to inacitvity in ${message.unassignTime} `;
        if (Number(message.unassignTime) > 1) {
          body += "minutes";
        } else {
          body += "minute";
        }

        showNotification({
          title: `Unassign Warning -  ${message.conversation.contactName}`,
          body: body,
          actions: [],
          data: {
            conversationBaseId: message.conversation.id
          }
        });
      }),
    [
      navigate,
      profile.id,
      currentconversationBaseId,
      subscribe,
      showNotification,
      settings.unassignWarning
    ]
  );

  React.useEffect(
    () =>
      subscribe("conversation-assigned", (message) => {
        if (!settings.conversationAssigned) {
          return;
        }
        if (message.conversation.assignedUserId !== profile.id) {
          return;
        }
        if (Notification.permission == "granted") {
          showNotification({
            title: `Conversation assigned-  ${message.conversation.contactName}`,
            body: `You have been assigned a conversation`,
            actions: [],
            data: {
              conversationBaseId: message.conversation.id
            }
          });
        } else {
          if (message.assigningUser.id != profile.id) {
            displayReassignmentNotification(
              message.conversation,
              message.conversation.lastMessageText,
              message.assigningUser.name
            );
          }
        }
      }),
    [
      subscribe,
      showNotification,
      profile.id,
      settings.conversationAssigned,
      displayReassignmentNotification
    ]
  );

  React.useEffect(
    () =>
      subscribe("skill-assigned", (message) => {
        if (!settings.conversationAssigned) {
          return;
        }
        if (!profile.skillIds.includes(message.skillId)) {
          return;
        }
        if (Notification.permission == "granted") {
          showNotification({
            title: `Conversation assigned-  ${message.conversation.contactName}`,
            body: `You have been assigned a conversation`,
            actions: [],
            data: {
              conversationBaseId: message.conversation.id
            }
          });
        } else {
          displayReassignmentNotification(
            message.conversation,
            message.conversation.lastMessageText,
            message.assigningUser.name
          );
        }
      }),
    [
      subscribe,
      showNotification,
      profile.id,
      settings.conversationAssigned,
      displayReassignmentNotification,
      profile.skillIds
    ]
  );

  React.useEffect(
    () =>
      subscribe("team-assigned", (message) => {
        if (!settings.conversationAssigned) {
          return;
        }
        if (!profile.teamIds.includes(message.teamId)) {
          return;
        }
        if (Notification.permission == "granted") {
          showNotification({
            title: `Conversation assigned-  ${message.conversation.contactName}`,
            body: `You have been assigned a conversation`,
            actions: [],
            data: {
              conversationBaseId: message.conversation.id
            }
          });
        } else {
          displayReassignmentNotification(
            message.conversation,
            message.conversation.lastMessageText,
            message.assigningUser.name
          );
        }
      }),
    [
      subscribe,
      showNotification,
      profile.id,
      settings.conversationAssigned,
      displayReassignmentNotification,
      profile.teamIds
    ]
  );

  function closeNotification(id: string) {
    const uniqueReassignmentContainer = document.getElementById(id);
    if (uniqueReassignmentContainer === null) {
      return;
    }
    uniqueReassignmentContainer.remove();
  }

  return (
    <NotificationContext.Provider
      value={{
        setCurrentconversationBaseId,
        settings,
        updateSettings,
        displayReassignmentNotification
      }}
    >
      <div
        id="notification-container"
        className="fixed flex flex-col justify-center items-end top-20 w-full pr-6 pointer-events-none z-50"
        ref={notificationContainer}
      ></div>
      {children}
    </NotificationContext.Provider>
  );
}
